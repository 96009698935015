import styled from 'styled-components';
import realtimeFonts from '../../../fonts';
import {goalText, insightsItem, insightsNumber, progressWrapper, section, title} from '../../windowItems/windowItems.css';
import {dFlex} from '../../../../../globalVariables/GlobalFlex';
import {WHITE2} from '../../../../../globalVariables/GlobalVariables';

export const container = styled.div<{$isGivechak: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 5px;
  height: 100%;
  justify-content: ${p=> p.$isGivechak? 'space-between' : 'unset'};
`;
export const content = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  ${section} {
    padding-inline: 12px;
    ${title} {
      min-width: max-content;
    }
  }
`;
export const details = styled.div`
  padding-bottom: 5px;
  height: 50%;
  min-height: min-content;
  justify-content: space-between;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  ${progressWrapper} {
    position: absolute;
    inset-inline-end: 20px;
    bottom: 20px;
  }
  ${goalText} {
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
  }
`;
export const stats = styled.div<{isListFull: boolean}>`
  margin-top: 10px;
  ${realtimeFonts.text}
  height: 98%;
  min-width: fit-content;
  display: flex;
  flex-direction: column;  
  justify-content: ${p=> p.isListFull? 'space-between' : 'flex-end'};
  ${insightsItem} {
    margin-bottom: ${p=> p.isListFull ?0:5}px;   
  }
  ${insightsNumber} {
    padding: 0;
    width: 63px;
    line-height: 10px;
    text-align: center;
  }
`;
export const tableWarp = styled.div`
  background: ${WHITE2};
  border-radius: 10px;
`;
export const tableHeader = styled.div`
  ${dFlex}
  width: 80%;
  ${realtimeFonts.text};
  line-height: 17px;
  font-weight: 800;
  text-align: center;
  margin-block-start:15px;
`;
export const tableCell = styled.div`
  width: 33%;
  margin-block-end:15px;
`;
export const tableRow = styled.div`
  ${dFlex}
  width: 80%;
  text-align: center;
  ${realtimeFonts.text};
  line-height: 17px;
  font-weight: 600;
`;